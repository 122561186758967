import css from './Products.module.css';
import { productsListItems } from './productsListItems';
const Products = () => {
  return (
    <div className={css.container}>
      <ul className={css.grid}>
        {productsListItems.map((product, index) => (
          <li className={css.grid__box} key={index}>
            <figure className={css.grid__boxCard}>
              <div className={css.grid__card}>
                <img
                  src={product.photo}
                  alt={product.photoDescription}
                  className={css.productPhoto}
                />
                <p className={css.grid__textOnTop}>{product.text}</p>
                {product.link && (
                  <p className={css.imgSource}>
                    <a href={product.link}>
                      Image {product.author ? 'by ' + product.author : ''} on
                      Freepik
                    </a>
                  </p>
                )}
              </div>
              <figcaption>
                <p className={css.grid__cardDescription}>{product.cardName}</p>
              </figcaption>
            </figure>
          </li>
        ))}
      </ul>
    </div>
  );
};
export default Products;
