import css from './AboutComponent.module.css';
import sklep from './image/picture1.jpg';
export const AboutComponent = () => {
  return (
    <div className={css.container}>
      <img
        src={sklep}
        alt="Zdjęcie frontu sklepu i budynku, w którym się znajduje."
        className={css.image}
      />
      <div>
        <h3 className={css.title}>Horn "u Roga"</h3>
        <p className={css.paragraph}>
          Jesteśmy firmą działającą na rynku od prawie 40 lat. Początkowo jako
          Firma Handlowa D.W. RÓG, a od 1995 roku jako Firma Handlowa "HORN-1".
          Specjalizujemy się w sprzedaży szerokiej gamy artykułów chemicznych
          (farby, tynki, kleje, silikony, pianki), artykułów metalowych oraz
          elektronarzędzi. Dodatkowo oferujemy wypożyczalnię sprzętu, sprzedaż
          ratalną, negocjacje cen oraz atrakcyjne rabaty. Wychodząc naprzeciw
          potrzebom naszych klientów, zapewniamy dowóz towaru w obrębie gminy, a
          także fachowe doradztwo, wizualizację produktów i pomoc w wyborze
          najlepszego rozwiązania. Posiadamy również mieszalnik do farb, co
          pozwala na precyzyjne dopasowanie kolorów do indywidualnych potrzeb
          klientów. Dla jeszcze większej wygody udostępniamy własny parking.
          Naszym celem jest kompleksowa obsługa, dlatego dokładamy wszelkich
          starań, aby sprostać oczekiwaniom każdego klienta. Dziękujemy za
          zaufanie, którym obdarzyli nas Państwo przez te lata, i serdecznie
          zapraszamy do skorzystania z naszej bogatej oferty!
        </p>
      </div>
    </div>
  );
};
