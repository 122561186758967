import bonBanner from './images/mainBanner/bonBanner.jpg';
import bonMobile from './images/mainBanner/bonMobile.jpg';
import wypozyczalnia from './images/mainBanner/wypozyczalnia.jpg';
import wypozyczalniaMobile from './images/mainBanner/wypozyczalniaMobile.jpg';
import sklepWelcome from './images/mainBanner/sklepWelcome.jpg';
import sklepWelcomeMobile from './images/mainBanner/sklepWelcomeMobile.jpg';
import mieszalnikDesktop from './images/mainBanner/paintmixerDesktop.jpg';
import mieszalnikMobile from './images/mainBanner/paintmixerMobile.jpg';

export const mainBannerItems = [
  {
    original: sklepWelcome,
    mobileImg: sklepWelcomeMobile,
    description:
      'Zdjęcie budynku sklepu z napisem "Witaj w sklepie Horn u Roga"',
  },
  {
    original: bonBanner,
    mobileImg: bonMobile,
    description: 'Bon podarunkowy do sklepu Horn',
  },
  {
    original: wypozyczalnia,
    mobileImg: wypozyczalniaMobile,
    description: 'Wypożyczalnia sprzętu w sklepie',
    link: 'https://www.freepik.com/free-photo/professional-builder-work-clothes-works-with-cutting-tool_14284940.htm#page=2&query=contruction%20tools&position=0&from_view=search&track=ais&uuid=c21e40b8-5967-4cf5-9e5f-f70a90a517d8',
    author: 'pvproductions',
  },
  {
    original: mieszalnikDesktop,
    mobileImg: mieszalnikMobile,
    description: 'Testbaner',
  },
];
